<template>
  <div class="form-elements">
    <div class="row">
      <div class="flex xs12">
        <vac-card :title="$t('Add new group')">
          <form @submit.prevent="onSubmit">

            <div class="row">
              <div class="flex md12 sm6 xs12">
                <va-input
                  v-model="name"
                  placeholder="Name"
                  class="input--bg"
                />
              </div>
            </div>

            <div class="row">
              <div class="flex xs12">
                <va-tabs grow v-model="tabValue" style="width: 100%;">
                  <va-tab
                    v-for="title in tabTitles.slice()"
                    :key="title">
                    {{ title }}
                  </va-tab>
                </va-tabs>
              </div>
            </div>

            <div v-if="tabValue === 0">

              <div class="row">

                <div class="column flex md4 sm6 xs12"
                  v-for="(data, index) in users"
                  v-bind:key="index">

                  <div class="flex"
                    v-for="user in data"
                    v-bind:key="user.username">
                    <va-checkbox
                      :label="user.username"
                      v-model="usersCheckboxes"
                      :arrayValue="user.id"
                    />
                  </div>

                </div>

              </div>

            </div>

            <div v-if="tabValue === 1">
              <div class="row">
                <div class="column flex md4 sm6 xs12"
                  v-for="(data, index) in brands"
                  v-bind:key="index">

                  <div class="flex"
                    v-for="brand in data"
                    v-bind:key="brand.name">
                    <va-checkbox
                      :label="brand.name"
                      v-model="brandsCheckboxes"
                      :arrayValue="brand.id"
                    />
                  </div>

                </div>
              </div>
            </div>

            <div v-if="tabValue === 2">
              <div class="row">
                <div class="column flex md4 sm6 xs12"
                  v-for="(data, index) in licenses"
                  v-bind:key="index">

                  <div class="flex"
                    v-for="license in data"
                    v-bind:key="license.name">
                    <va-checkbox
                      :label="license.name"
                      v-model="licensesCheckboxes"
                      :arrayValue="license.id"
                    />
                  </div>

                </div>
              </div>
            </div>

            <div v-if="tabValue === 3">

              <div class="row">

                <div class="column flex md4 sm6 xs12"
                  v-for="(data, index) in retailers"
                  v-bind:key="index">

                  <div class="flex"
                    v-for="retailer in data"
                    v-bind:key="retailer.name">
                    <va-checkbox
                      :label="retailer.name"
                      v-model="retailersCheckboxes"
                      :arrayValue="retailer.id"
                    />
                  </div>

                </div>

              </div>

            </div>

            <div v-if="tabValue === 4">
              <Permissions
                :permissions="permissions"
                :permissions-checkboxes="permissionsCheckboxes"
                :group-permissions="groupPermissions"
                :categories-permission-type="categoriesPermissionType"
                :categories-list="groupCategories"
                @assignProps="assignProps"
              />
            </div>

            <va-button class="btn--wide"
              type="submit"
              :style="{marginTop: '64px'}">
              Save
            </va-button>
          </form>
          <va-progress-bar v-if="loading"
            indeterminate
            :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"></va-progress-bar>
        </vac-card>
      </div>
    </div>
  </div>
</template>

<script>
import VaButton from 'vuestic-ui/src/components/vuestic-components/va-button/VaButton';
import { sortArrayOfObject } from "../../services/Helpers/HelperObject";
import HelperUser from "../../services/Helpers/HelperUser";
import { showToastError, showToastSuccess } from "../../services/Helpers/HelperToast";
import API from "../../services/API/API";
import Permissions from "@/components/groups/tabs/Permissions";

export default {
  name: 'Add',
  components: { VaButton, Permissions },
  async created() {
    const userInfo = HelperUser.getUserLicensesRetailers();
    this.retailers = sortArrayOfObject(userInfo.retailers.data, "name");
    this.retailers = this.collectColumns(this.retailers);
    this.licenses = sortArrayOfObject(userInfo.licenses.data, "name");
    this.licenses = this.collectColumns(this.licenses);
    await API.APIGet(`${process.env.VUE_APP_API_URL}/brands/all`, {}, (data) => {
      this.brands = sortArrayOfObject(data, "name");
      this.brands = this.collectColumns(this.brands);
    })
    this.getUsers();
    this.getPermissions();
  },
  data() {
    return {
      name: '',
      tabValue: 0,
      retailersCheckboxes: [],
      licensesCheckboxes: [],
      brandsCheckboxes: [],
      usersCheckboxes: [],
      permissionsCheckboxes: { 5: true },
      tabTitles: ['Users', 'Brands', 'Licenses', 'Retailers', 'Permissions'],
      licenses: [],
      brands: [],
      retailers: [],
      users: [],
      permissions: [],
      groupPermissions: [],
      groupCategories: [],
      categoriesPermissionType: true,
      loading: false
    };
  },
  methods: {
    assignProps(key, value) {
      if (!!this?.[key] || typeof this[key] === "boolean") {
        this.$set(this, key, value);
      }
    },

    getUsers() {
      API.APIGet(`${process.env.VUE_APP_API_URL}/users`, {}, data => {
        let sortUsers = sortArrayOfObject(data, "username");
        this.users = this.collectColumns(sortUsers);
      });
    },

    getPermissions() {
      API.APIGet(`${process.env.VUE_APP_API_URL}/permissions`, {}, data => {
        this.permissions = sortArrayOfObject(data, "name");
      });
    },

    collectColumns(data) {
      const length = Math.ceil(data.length / 3);
      let output = [];
      for (let index = 0; index < 3; index++) {
        const multiplier = index * length;
        const innerArray = data.slice(multiplier, multiplier + length);
        output.push(innerArray);
      }
      return output;
    },

    onSubmit() {
      this.loading = true;

      const data = {
        group: {
          name: this.name,
        },
        licenses: [],
        brands: [],
        retailers: [],
        users: [],
        permissions: [],
        categories: []
      };

      const retailers = this.retailersCheckboxes;
      const licenses = this.licensesCheckboxes;
      const brands = this.brandsCheckboxes;
      const users = this.usersCheckboxes;
      const permissions = Object.keys(this.permissionsCheckboxes);

      data.licenses = licenses;
      data.brands = brands;
      data.retailers = retailers;
      data.users = users;
      data.permissions = permissions.map(p => parseInt(p));
      data.categories = this.groupCategories.map(elem => elem.id);

      let saved = this.$store.dispatch('createGroup', data);

      saved.then(() => {
        this.loading = false;
        showToastSuccess("Group saved success", this.$toast);
      }).catch(error => {
        this.loading = false;
        showToastError(error, this.$toast);
      });

    },
  },
};
</script>

<style lang="scss">
.row.row-inside {
  max-width: none;
}
</style>
